import Backpacker from '../../Assets/backpacker.jpg'
import Turista from '../../Assets/happy-tourist.jpg'
import Amigas from '../../Assets/amigas--paris.jpg'



export const myMockFeedback = [
        {name:'Felipe Oliveira', title:'Excelente empresa', description:'A empresa me levou para o meu destino com segurança e comodidade!', avaliation:5, picture:Backpacker},
        {name:'Caio Pereira', title:'Viagem para Roma', description:'Adorei fazer a cotação da minha viagem com a Or Rhormens!!', avaliation:4, picture:Turista},
        {name:'Felipe Oliveira', title:'Paris', description:'Excelente !', avaliation:5, picture:Amigas},
]