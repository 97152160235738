// export const reservedWord = ['N','I','B'];
export const reservedWord = ['NOVIDADE','NOTÍCIA','NEWS','INFORMAÇÃO','BLOG'];

 

 

 

 
