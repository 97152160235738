import styled from "styled-components";


export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  text-align: center;
 font-size: 28px;
 font-weight: bold;
 font-family: arkit-light;
  padding: 40px 0px;
  border-bottom: 1px solid silver;
`;
