import styled from "styled-components";


export const DataContainer = styled.div`
width: 170px;
height: 70px;
/* margin: 20px; */
input{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #838179;
}
`